import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./passportdetails.component.css";
// import Bagphoto from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
// import Button from "@material-ui/core/Button";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Navbar from "../header/header.component";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import { Country, State, City } from "country-state-city";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../assets/loader.gif";
import { APP_ENV, switchUrls } from "../../api/index.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import Sidebarcontent from "../sidebar/Sidebar.jsx";
import Footer from "../mainfooter/footerblack.jsx";

const baseURL = switchUrls("passport");
const gateway_frontend = switchUrls("gateway_frontend");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
    },
  },

  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class BagDetails extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imageUrl: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/passport.png",
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "passport",
      valuable_desc: "",
      passport: "",
      dateofissue: "",
      Surname: "",
      givenName: "",
      nationality: "",
      placeofissue: "",
      address: "",
      validityperiod: "",
      snackbaropen: false,
      snackbarmsg: "",
      sidebar: true,
      subMenu: true,
      message: "",
      // vehicle_brand:"",
      // vehicle_otherkeys:"",
      // vehicle_licensenumber:"",
      // chasisnumber:"",
      // drivinglicense:"",
      showLoader: true,
      expirydate: "",
      warranty_period: "",
      selectedOption: "Day",
      editLnfField: false,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      passport_imagelocation: "",
      digital_product: "",
      journey_enddate: "",
      journey_startdate: "",
      imagecroploading: false,
      errorstate: null,
      croperror: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  uploadImg() {
    this.setState({
      uploaderror: false,
      message: false,
      imagemodal: false,
      imagecroploading: false,
      croperror: false,
    });
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  async handleChange(event) {
    //console.log("..............................");
    //console.log(".l");
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log(sizeinmb);
      if (sizeinmb > 5) {
        this.setState({
          message: "File size should be less than 5 MB",
          uploaderror: true,
          imagemodal: true,
        });
      } else {
        this.setState({ showLoader: true });
        const imageDimension = await this.imageSize(event.target.files[0]);
        console.log(imageDimension);
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight:
            imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(
          event.target.files[0],
          options
        );
        console.log(compressedFile);
        const selectedImage = compressedFile;
        console.log(selectedImage, "image");
        this.setState({ showLoader: false });
        if (selectedImage?.type) {
          if (
            selectedImage?.type === "image/jpeg" ||
            selectedImage?.type === "image/png" ||
            selectedImage?.type === "image/gif"
          ) {
            this.setState({
              imagemodal: true,
              selectedImage: compressedFile,
              imageUrl: URL.createObjectURL(compressedFile),
              isuploading: true,
              imagecroploading: false,
            });
          } else if (
            selectedImage?.type === "image/heic" ||
            selectedImage?.type === "image/heif"
          ) {
            const convertedImageBlob = await heic2any({
              blob: compressedFile,
              toType: "image/jpeg",
              quality: 0.1, // cuts the quality and size
            });
            console.log(convertedImageBlob);
            let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
            console.log(sizeinmb);
            if (sizeinmb > 5) {
            }
            this.setState({
              imagemodal: true,
              selectedImage: compressedFile,
              imageUrl: URL.createObjectURL(compressedFile),
              isuploading: true,
              imagecroploading: false,
            });
          } else {
            this.setState({
              message: "Only Png and Jpeg Images Are Acceptable",
              uploaderror: true,
              imagemodal: true,
              imagecroploading: false,
            });
          }
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = "";
        }
      }
    } else {
      console.log("failed");
    }
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/passport/getPassportDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        const convertDateToYYMMDD = (givenDate) => {
          if (givenDate === null) {
            return null;
          }
          const date = new Date(givenDate);

          const year = date.getFullYear();
          let month = date.getMonth() + 1;
          month = month < 10 ? `0${month}` : month;
          let day = date.getDate();
          day = day < 10 ? `0${day}` : day;

          return year + "-" + month + "-" + day;
        };
        this.setState({
          passport: response.data?.passport,
          Surname: response.data?.Surname,
          tag_number: response.data?.tag_number,
          tag_status: response.data?.tag_status,
          valuable_type: response.data?.valuable_type,
          givenName: response.data?.givenName,
          nationality: response.data?.nationality,
          valuable_desc: response.data?.valuable_desc,
          dateofissue: convertDateToYYMMDD(response.data?.dateofissue),
          address: response.data?.address,
          placeofissue: response.data?.placeofissue,
          validityperiod: response.data?.validityperiod,
          expirydate: convertDateToYYMMDD(response.data?.expirydate),
          warranty_period: response.data?.warranty_period,
          selectedOption: response.data?.warranty_period?.split(" ")[1],
          passport_imagelocation: response?.data?.passport_imagelocation,
          digital_product: response?.data?.digital_product,
          journey_enddate: convertDateToYYMMDD(response?.data?.journey_enddate),
          journey_startdate: convertDateToYYMMDD(
            response?.data?.journey_startdate
          ),
        });
      })
      .catch((error) => {
        console.log(error);
      });

    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  handleChangeWarranty = (e) => {
    // const { name, value } = event.target;
    // console.log(e.target.value, "values");
    // this.setState({ [e.target.name]: e.target.value });
    this.setState({
      warranty_period: `${e.target.value} ${this.state.selectedOption}`,
    });
    // this.setState({
    //   [name]: value + " " + this.state.selectedOption,
    // });
  };
  handleSelectChange = (event) => {
    // console.log(event.target.value, this.state.warranty_period, "event");
    this.setState({
      selectedOption: event.target.value,
      warranty_period: `${this.state.warranty_period?.split(" ")[0]} ${
        event.target.value
      }`,
    });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value.trimStart() });

    if (e.target.name === "valuable_desc") {
      if (e.target.value.length === 0) {
        this.setState({ valuable_desc: " " });
      }
    }
    if (e.target.name === "address") {
      if (e.target.value.length === 0) {
        this.setState({ address: " " });
      }
    }
    if (e.target.name === "placeofissue") {
      if (e.target.value.length === 0) {
        this.setState({ placeofissue: " " });
      }
    }
    if (e.target.name === "validityperiod") {
      if (e.target.value.length === 0) {
        this.setState({ validityperiod: " " });
      }
    }
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  deleteimage = (e) => {
    console.log("clicked");
    this.setState({ showLoader: true });
    axios
      .post(`${baseURL}/api/passport/deletepassportimage`, this.state)
      .then((response) => {
        console.log(response);
        if (response.data === "Bag Image removed") {
          this.setState({
            imageUrl: "",
            passport_imagelocation: null,
            passport_imagename: null,
            showLoader: false,
          });
        } else if (response.data === "wrong data") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
            showLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
        this.setState({ showLoader: false });
      });
  };

  cropsetup(e) {
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob((blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      this.setState({
        selectedImage: file,
        imageUrl: URL.createObjectURL(blob),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
      });
    });
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.setState({ showLoader: true });
    axios
      .post(
        `${baseURL}/api/passport/editPassportDetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        console.log(response);
        if (response.data === "Passport Details Updated Successfully") {
          if (this?.state?.selectedImage !== null) {
            const fd = new FormData();
            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);
            axios
              .post(`${baseURL}/api/passport/passportimageupload`, fd)
              .then((res) => {
                console.log(res);
                this.setState({
                  showLoader: false,
                  snackbaropen: true,
                  snackbarmsg: "Bag Details Updated Successfully",
                });
                if (APP_ENV === "local") {
                  window.location.href = gateway_frontend;
                } else {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }
              })
              .catch((error) => {
                this.setState({
                  showLoader: false,
                  snackbaropen: true,
                  snackbarmsg: "Bag Details Updated Failed",
                });
              });
          } else {
            this.setState({ showLoader: false });
            if (APP_ENV === "local") {
              window.location.href = gateway_frontend;
            } else {
              window.location.href =
                "/dashboard/" + localStorage.getItem("uid");
            }
          }
        } else if (response.data === "Passport Details Update Failed") {
          this.setState({ showLoader: false });
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        }
      })
      .catch((error) => {
        this.setState({ showLoader: false });
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Bag Details Update Failed",
        });
      });
  };

  render() {
    const {
      imageUrl,
      tag_number,
      dateofissue,
      warranty_period,
      passport,
      valuable_type,
      valuable_desc,
      Surname,
      givenName,
      nationality,
      placeofissue,
      address,
      validityperiod,
      sidebar,
      subMenu,
      expirydate,
      selectedOption,
      imageUrlvector,
      imagemodal,
      crop,
      uploaderror,
      passport_imagelocation,
      message,
      tag_status,
      digital_product,
      journey_startdate,
      journey_enddate,
    } = this.state;
    const today = () => {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var today = year + "-" + month + "-" + day;
      return today;
    };

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    // console.log("state : ", this.state);
    return (
      <>
        <Navbar />
        <div className="editPetDetails-main-wrapper">
          <div className="editPetDetails-left-wrapper">
            <Sidebarcontent />
          </div>
          <div className="form-group multi-preview">
            {(this.fileArray || []).map((url) => (
              <img
                src={url}
                className="ml-4 mr-2"
                alt="..."
                height="70px"
                width="70px"
              />
            ))}
          </div>
          <div className="editPetDetails-right-wrapper">
            <div id="breadcrumbs" className="mb-3"></div>
            {tag_status !== "deactive" ? (
              <>
                <div className="petDetails ">
                  <div className="editPetDetails-form-container ">
                    <div className="editPetDetails-form-container-top">
                      <div className="editPetDetails-user-img">
                        {imageUrl?.length ? (
                          <>
                            <img
                              alt="imageurl"
                              src={imageUrl}
                              className="editPetDetails-image"
                            />
                          </>
                        ) : passport_imagelocation ? (
                          <>
                            <img
                              alt="imagelocation"
                              src={passport_imagelocation}
                              className="editPetDetails-image"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="imageyrlvector"
                              src={imageUrlvector}
                              className="editPetDetails-image"
                            />
                          </>
                        )}
                        <div className="editPetDetails-edit-btn-wrap">
                          <label
                            htmlFor="upload"
                            className="editPetDetails-edit-button-image"
                            style={{ cursor: "pointer" }}
                          >
                            <MdIcons.MdEdit /> Change
                          </label>

                          <input
                            ref={this.fileInputRef}
                            id="upload"
                            accept="image/*"
                            className="editPetDetails-input-user-img"
                            type="file"
                            onChange={this.handleChange}
                          />
                          {passport_imagelocation ? (
                            <>
                              <label
                                className="editPetDetails-delete-button-image"
                                onClick={this.deleteimage}
                              >
                                <AiIcons.AiFillDelete /> Remove
                              </label>
                            </>
                          ) : null}
                        </div>
                        <div
                          className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                          style={{ gap: "6px" }}
                        >
                          <img
                            alt="qr"
                            src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <p style={{ marginTop: "15px" }}>
                            <b>
                              {tag_number && tag_number !== "-"
                                ? tag_number
                                : "N/A"}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="editPetDetails-form-container-bottom mt-3"
                      style={{ width: "100%" }}
                    >
                      <p style={{ textAlign: "center" }}>
                        <strong>Passport Details</strong>
                      </p>
                      <form
                        className="editPetDetails-form"
                        onSubmit={this.submitHandler}
                      >
                        {/* fist/given name and surename */}
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              required
                              type="text"
                              variant="outlined"
                              label="Given/First Name"
                              name="givenName"
                              value={givenName}
                              onChange={this.changeHandler}
                              inputProps={{ maxLength: 30 }}
                            />
                          </div>

                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              required
                              type="text"
                              variant="outlined"
                              label="Surname"
                              name="Surname"
                              value={Surname}
                              onChange={this.changeHandler}
                              inputProps={{ maxLength: 30 }}
                            />
                          </div>
                        </div>

                        {/* expiry date and passport no */}
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              required
                              label="Expiry Date"
                              variant="outlined"
                              type="date"
                              name="expirydate"
                              value={expirydate}
                              onChange={this.changeHandler}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: today,
                              }}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              variant="outlined"
                              label="Passport Number"
                              name="passport"
                              value={passport}
                              onChange={this.changeHandler}
                              inputProps={{ maxLength: 25 }}
                            />
                          </div>
                        </div>
                        {digital_product ? (
                          <>
                            <div className="two-field-wrapper">
                              <div className="editPetDetails-input-wrapper">
                                <TextField
                                  required
                                  type="date"
                                  // className="bagactivation-input-tag"
                                  variant="outlined"
                                  label="Journey Start Date"
                                  name="journey_startdate"
                                  value={journey_startdate}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="editPetDetails-input-wrapper">
                                <TextField
                                  required
                                  type="date"
                                  // className="bagactivation-input-tag"
                                  variant="outlined"
                                  label="Journey End Date"
                                  name="journey_enddate"
                                  value={journey_enddate}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                        {/* nationality and date of issue */}
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <FormControl>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Nationality
                              </InputLabel>
                              <Select
                                variant="outlined"
                                label="Nationality"
                                name="nationality"
                                required
                                value={
                                  nationality?.charAt(0).toUpperCase() +
                                  nationality?.slice(1)
                                }
                                onChange={this.changeHandler}
                              >
                                {Country?.getAllCountries()?.map((c) => {
                                  return (
                                    <MenuItem key={c.isoCode} value={c.name}>
                                      {c.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                          {dateofissue && !digital_product && (
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                required
                                label="Date Of Issue"
                                variant="outlined"
                                type="date"
                                name="dateofissue"
                                value={dateofissue}
                                onChange={this.changeHandler}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: new Date().toISOString().split("T")[0], // Set minimum selectable date to today
                                }}
                              />
                            </div>
                          )}
                        </div>

                        {valuable_desc && address && (
                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                type="text"
                                variant="outlined"
                                label="Passport Description"
                                name="valuable_desc"
                                value={valuable_desc}
                                onChange={this.changeHandler}
                                inputProps={{ maxLength: 35 }}
                              />
                            </div>

                            <div
                              className="editPetDetails-input-wrapper"
                              id="select-pet-age"
                            >
                              <TextField
                                type="text"
                                label="Address"
                                variant="outlined"
                                name="address"
                                value={address}
                                onChange={this.changeHandler}
                                inputProps={{ maxLength: 35 }}
                              />
                            </div>
                          </div>
                        )}

                        {placeofissue && validityperiod && (
                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                type="text"
                                variant="outlined"
                                label=" Place Of Issue"
                                name="placeofissue"
                                value={placeofissue}
                                onChange={this.changeHandler}
                                inputProps={{ maxLength: 12 }}
                              />
                            </div>
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                type="text"
                                label="validity period"
                                variant="outlined"
                                name="validityperiod"
                                value={validityperiod}
                                onChange={this.changeHandler}
                                inputProps={{ maxLength: 12 }}
                              />
                            </div>
                          </div>
                        )}
                        {warranty_period && (
                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                type="number"
                                variant="outlined"
                                maxLength="20"
                                label="Warranty Period"
                                name="warranty_period"
                                value={warranty_period.split(" ")[0]}
                                onChange={this.handleChangeWarranty}
                              />
                            </div>
                            <div className="editPetDetails-input-wrapper">
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  style={{
                                    marginTop: "-7px",
                                    marginLeft: "13px",
                                  }}
                                >
                                  Warranty Duration
                                </InputLabel>
                                <Select
                                  labelId="valuable-type-label"
                                  id="valuable-type-select"
                                  required
                                  label=" Warranty Duration"
                                  value={selectedOption}
                                  onChange={this.handleSelectChange}
                                  variant="outlined"
                                >
                                  <MenuItem value="Day">Day</MenuItem>
                                  <MenuItem value="Month">Month</MenuItem>
                                  <MenuItem value="Year">Year</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        )}

                        {/* button */}
                        <div className="editPetDetails-btn-row">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ width: "100%" }}
                            className="submit-login bg-warning text-white mt-2"
                          >
                            {this.state.redirecttopetparent ? "NEXT" : "SUBMIT"}
                            {/* {this.state.redirecttopetparent ? "SUBMIT" : "NEXT"} */}
                          </Button>

                          <Button
                            type="button"
                            variant="contained"
                            style={{ width: "100%" }}
                            className="bg-white mt-2 cancel-btn submit-login "
                            onClick={() => {
                              window.location.href =
                                "/dashboard/" + localStorage.getItem("uid");
                            }}
                          >
                            CANCEL
                          </Button>
                        </div>

                        {this.state.showLoader && (
                          <div className="loader-container">
                            <div className="loader">
                              <img
                                src={Loader}
                                alt="Loading..."
                                loading="lazy"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          </div>
                        )}

                        {/* <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={this.state.snackbaropen}
                      autoHideDuration={8000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          arial-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    /> */}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>Passport Tag Is Deactivated</h1>
            )}
          </div>
          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
        </div>
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button onClick={this.handelimagecrop}>Submit</Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
      </>
    );
  }
}
