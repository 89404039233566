import React from "react";
import './footer.component.css'

function footerblack() {
  return (
    <>
      <div className="footer">
        <div class="left-content">
          © {new Date().getFullYear()} Powered by 3Y Ventures LLP All Rights
          Reserved |{" "}
          <a
            href="https://www.tag8.in/page/terms-and-conditions"
            style={{ color: "white" }}
          >
            Legal Terms & Conditions
          </a>
        </div>
      </div>
    </>
  );
}

export default footerblack;
