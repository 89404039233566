import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../reportlost.component.css";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Navbar from "../../header/header.component";
import MapContainer from "../testMap";
import "date-fns";
// import Userimage from '../../../assets/user2.png';
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Loader from "../../../assets/loader.gif";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("passport");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const Name = localStorage.getItem("name");
let token = usertoken;

function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Bag Details"];
}

const steps = getSteps();

export default class EditReportLost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alignment: "left",
      tag_number: "",
      lf_status: "lost",
      valuable_type: "",
      description: "",
      identification_mark: "",
      owner_firstname: "",
      owner_lastname: "",
      owner_phone: "",
      owner_altphone: "",
      owner_email: "",
      owner_altemail: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      snackbaropen: false,
      sidebar: true,
      subMenu: true,
      editlocation: false,
      snackbarmsg: "",
      activeStep: 0,
      showLoader: true,
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    // this.getCoordinates  = this.getCoordinates.bind(this)
  }

  handleAlignment = (event, alignment) => this.setState({ alignment });

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  componentDidMount() {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    axios
      .get(
        `${baseURL}/api/passport/getReportLost/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        this.setState({
          tag_number: response.data.tag_number,
          description: response.data.description,
          identification_mark: response.data.identification_mark,
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          owner_phone: response.data.owner_phone,
          owner_altphone: response.data.owner_altphone,
          owner_email: response.data.owner_email,
          owner_altemail: response.data.owner_altemail,
          lost_text: response.data.lost_text,
          lost_date: response.data.lost_date,
          reward: response.data.reward,
          rewardcurrency: response.data.rewardcurrency,
          lost_timeone: response.data.lost_timeone,
          lost_timetwo: response.data.lost_timetwo,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          chasisnumber: response.data.chasisnumber,
          drivinglicense: response.data.drivinglicense,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${baseURL}/api/passport/getPassportDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        this.setState({
          tag_number: response.data.tag_number,
          valuable_type: response.data.valuable_type,
          bag_brand: response.data.bag_brand,
          bag_color: response.data.bag_color,
          description: response.data.description,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }
  // getCoordinates(position){
  //   console.log(position)
  //   this.setState({
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude
  //   })
  // }
  sendlatitude = (data) => {
    console.log(data);
    this.setState({
      latitude: data.lat,
      longitude: data.lng,
    });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios
      .post(
        `${baseURL}/api/passport/editReportLost/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        console.log(response);
        if (response.data === "Report Lost Details Updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href =
            "/#/bag-poster/" + this.props.match.params.tag_number;
        } else if (response.data === "Report Lost Details Not Updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Report Lost Details Not Updated",
        });
      });
  };

  getStepContent = (stepIndex) => {
    const {
      lost_text,
      lost_date,
      latitude,
      longitude,
      lost_timeone,
      lost_timetwo,
      alignment,
      tag_number,
      valuable_type,
      description,
      identification_mark,
      other_nickname,
      other_noofkeys,
      owner_firstname,
      owner_lastname,
      owner_phone,
      owner_altphone,
      owner_email,
      owner_altemail,
      reward,
      rewardcurrency,
      editlocation,
      bag_brand,
      bag_color,
    } = this.state;
    switch (stepIndex) {
      case 0:
        return (
          <div className="text-center">
            <textarea
              rows="4"
              className="loss-details-body text-center p-3"
              style={{
                fontSize: "16px",
                borderColor: "gray",
                marginTop: "150px",
                marginLeft: "0px",
              }}
              name="lost_text"
              placeholder="Give a brief account of loss. [Eg] Were you in a cab?"
              value={lost_text}
              onChange={this.changeHandler}
            ></textarea>
            <br />
            <br />
            <div className="row mt-5 mb-4">
              <div className="col">
                <Button
                  type="button"
                  onClick={() => {
                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                  }}
                  variant="contained"
                  style={{ width: "200px" }}
                  className="submit-login cancel-btn mt-2"
                >
                  CANCEL
                </Button>
              </div>
              <div className="col text-center">
                <Button
                  variant="contained"
                  className="bg-warning nextback nextbutton mt-2"
                  onClick={this.handleNext}
                  style={{ width: "200px" }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="reportlost-location">
            <div className="reportlost-location-map-container">
              <MapContainer
                sendlatitude={this.sendlatitude}
                lostlatitude={this.state.latitude}
                lostlongitude={this.state.longitude}
                editlocation={this.state.editlocation}
              />
            </div>
            <div className="reportlost-location-input-cnt">
              <div className="reportlost-location-input-cnt-lat">
                <span className="">Latitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={latitude}
                  name="latitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>

              <div className="reportlost-location-input-cnt-lon">
                <span className="">Longitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={longitude}
                  name="longitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>
            </div>

            <div className="reportlost-location-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                value="right"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="date-time-loss">
            <span>
              <input
                type="date"
                className="date-time-loss-input"
                name="lost_date"
                value={lost_date}
                onChange={this.changeHandler}
              />
            </span>

            <span className="date-time-loss-time-picker">
              <h5 className="">Set indicative time range of loss</h5>

              <input
                type="time"
                className="date-time-loss-input"
                name="lost_timeone"
                value={lost_timeone}
                onChange={this.changeHandler}
              />

              <input
                type="time"
                className="date-time-loss-input"
                name="lost_timetwo"
                value={lost_timetwo}
                onChange={this.changeHandler}
              />
            </span>

            <div className="date-time-loss-btns">
              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
                style={{ width: "200px" }}
                className="submit-login cancel-btn mt-2"
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                className="bg-warning nextbutton mt-2"
                onClick={this.handleNext}
                style={{ width: "200px" }}
              >
                Next
              </Button>
              <Button
                variant="contained"
                className="mt-2"
                onClick={this.handleBack}
                style={{ width: "200px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="reportlost-container">
            <span className="reportlost-container-pet-details-span">
              <h4>Bag Details</h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <span>Owner ID *</span>

                  <input
                    type="textbox"
                    value={tag_number}
                    name="tag_number"
                    readOnly
                    required
                  />
                </div>
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <span>Valuable Type</span>

                  <select
                    className=""
                    value={valuable_type}
                    onChange={this.handleDropdownChange}
                  >
                    <option value="Luggage">Luggage</option>
                    <option value="Luggage">Luggage</option>
                    <option value="Backpacs">Backpacs</option>
                    <option value="Laptop Bag">Laptop Bag</option>
                    <option value="Briefcase">Briefcase</option>
                    <option value="Camera Bag">Camera Bag</option>
                    <option value="Gym Bag">Gym Bag</option>
                    <option value="Trolley Bag">Trolley Bag</option>
                    <option value="Suitcase">Suitcase</option>
                    <option value="Ladies Purse">Lasies Purse</option>
                    <option value="Sports Kit Bag">Sports Kit Bag</option>
                    <option value="Hand Bag/Duffel Bag">
                      Hand Bag/Duffel Bag
                    </option>
                    <option value="School Bag">School Bag</option>
                    <option value="Other Bags">Other Bags</option>
                  </select>
                </div>
              </span>

              <div className="reportlost-container-pet-details-span-input-cnt">
                <span>Bag Description</span>

                <input
                  type="textbox"
                  // className="input-pet-details"
                  id="nextfieldpetname"
                  maxLength="20"
                  name="description"
                  value={description}
                  onChange={this.changeHandler}
                />
              </div>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <span>Bag Color</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    value={bag_color}
                    name="bag_color"
                    readOnly
                    required
                  />
                </div>

                <div className="reportlost-container-pet-details-span-input-cnt">
                  <span>Bag Brand</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    value={bag_brand}
                    name="bag_brand"
                    readOnly
                    required
                  />
                </div>
              </span>
            </span>

            <span className="reportlost-container-pet-parent-details-span">
              <h4 className="">Bag Owner Details</h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <span>Bag Owner First Name</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    maxLength="20"
                    name="owner_firstname"
                    value={owner_firstname}
                    onChange={this.changeHandler}
                  />
                </div>

                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <span>Bag Owner Last Name</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    maxLength="20"
                    name="owner_lastname"
                    value={owner_lastname}
                    onChange={this.changeHandler}
                  />
                </div>
              </span>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <span>Email ID *</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    maxLength="20"
                    name="owner_email"
                    value={owner_email}
                    onChange={this.changeHandler}
                  />
                </div>

                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <span>Emergency Phone Number 1 *</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    maxLength="20"
                    name="owner_phone"
                    value={owner_phone}
                    onChange={this.changeHandler}
                  />
                </div>
              </span>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <span>Emergency Phone Number 2</span>

                  <input
                    type="textbox"
                    // className="input-pet-details"
                    maxLength="20"
                    name="owner_altphone"
                    value={owner_altphone}
                    onChange={this.changeHandler}
                  />
                </div>
              </span>
            </span>

            <span className="reportlost-container-owner-reward-span">
              <h4>
                <i className="fa fa-gift fa-2x"></i>
                <span>Owner Reward</span>
              </h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-owner-reward-span-select-cnt">
                  <span>Currency type</span>

                  <select
                    // className="input-pet-details-dropdown"
                    name="rewardcurrency"
                    value={rewardcurrency}
                    onChange={this.changeHandler}
                  >
                    <option hidden></option>
                    <option value="Rupees">Indian Rupees</option>
                    <option value="U.S. dollar">U.S. dollar</option>
                    <option value="Euro">Euro</option>
                    <option value="Dirham">Dirham</option>
                    <option value="Dinar">Dinar</option>
                    <option value="Yen">Yen</option>
                    <option value="Afghani">Afghani</option>
                    <option value="Lek">Lek</option>
                    <option value="New Kwanza">New Kwanza</option>
                    <option value="Dram">Dram</option>
                    <option value="Bahamian dollar">Bahamian dollar</option>
                    <option value="Bahrain dinar">Bahrain dinar</option>
                    <option value="Taka">Taka</option>
                    <option value="Barbados dollar">Barbados dollar</option>
                    <option value="Belorussian ruble">Belorussian ruble</option>
                    <option value="Belize dollar">Belize dollar</option>
                    <option value="Ngultrum">Ngultrum</option>
                    <option value="Boliviano">Boliviano</option>
                    <option value="Convertible Mark">Convertible Mark</option>
                    <option value="Pula">Pula</option>
                    <option value="Real">Real</option>
                    <option value="Brunei dollar">Brunei dollar</option>
                    <option value="Lev">Lev</option>
                    <option value="Burundi franc">Burundi franc</option>
                    <option value="Riel">Riel</option>
                    <option value="Canadian dollar">Canadian dollar</option>
                    <option value="Cape Verdean escudo">
                      Cape Verdean escudo
                    </option>
                    <option value="Chilean Peso">Chilean Peso</option>
                    <option value="Chinese Yuan">Chinese Yuan</option>
                    <option value="Colombian Peso">Colombian Peso</option>
                    <option value="Franc">Franc</option>
                    <option value="United States dollar">
                      United States dollar
                    </option>
                    <option value="Colón">Colón</option>
                    <option value="Croatian">Croatian</option>
                    <option value="Cuban Peso">Cuban Peso</option>
                    <option value="Koruna">Koruna</option>
                    <option value="Danish Krone">Danish Krone</option>
                    <option value="Djiboutian franc">Djiboutian franc</option>
                    <option value="Dominican Peso">Dominican Peso</option>
                    <option value="Egyptian pound">Egyptian pound</option>
                    <option value="Colón; U.S. dollar">
                      Colón; U.S. dollar
                    </option>
                    <option value="Nakfa">Nakfa</option>
                    <option value="Birr">Birr</option>
                    <option value="Fiji dollar">Fiji dollar</option>
                    <option value="Dalasi">Dalasi</option>
                    <option value="Lari">Lari</option>
                    <option value="Cedi">Cedi</option>
                    <option value="Quetzal">Quetzal</option>
                    <option value="Guinean franc">Guinean franc</option>
                    <option value="Guyanese dollar">Guyanese dollar</option>
                    <option value="Gourde">Gourde</option>
                    <option value="Lempira">Lempira</option>
                    <option value="Forint">Forint</option>
                    <option value="Icelandic króna">Icelandic króna</option>
                    <option value="Rupiah">Rupiah</option>
                    <option value="Iraqi Dinar">Iraqi Dinar</option>
                    <option value="Shekel">Shekel</option>
                    <option value="Jamaican dollar">Jamaican dollar</option>
                    <option value="Jordanian dinar">Jordanian dinar</option>
                    <option value="Tenge">Tenge</option>
                    <option value="Kenya shilling">Kenya shilling</option>
                    <option value="Kiribati dollar">Kiribati dollar</option>
                    <option value="Won">Won</option>
                    <option value="Kuwaiti Dinar">Kuwaiti Dinar</option>
                    <option value="Som">Som</option>
                    <option value="New Kip">New Kip</option>
                    <option value="Lats">Lats</option>
                    <option value="Lebanese pound">Lebanese pound</option>
                    <option value="Maluti">Maluti</option>
                    <option value="Liberian dollar">Liberian dollar</option>
                    <option value="Libyan dinar">Libyan dinar</option>
                    <option value="Litas">Litas</option>
                    <option value="Denar">Denar</option>
                    <option value="Malagasy Ariary">Malagasy Ariary</option>
                    <option value="Ringgit">Ringgit</option>
                    <option value="Rufiyaa">Rufiyaa</option>
                    <option value="Ouguiya">Ouguiya</option>
                    <option value="Mauritian rupee">Mauritian rupee</option>
                    <option value="Mexican peso">Mexican peso</option>
                    <option value="Leu">Leu</option>
                    <option value="Togrog">Togrog</option>
                    <option value="Metical">Metical</option>
                    <option value="Kyat">Kyat</option>
                    <option value="Namibian dollar">Namibian dollar</option>
                    <option value="Australian dollar">Australian dollar</option>
                    <option value="Nepalese rupee">Nepalese rupee</option>
                    <option value="New Zealand dollar">
                      New Zealand dollar
                    </option>
                    <option value="Gold cordoba">Gold cordoba</option>
                    <option value="Naira">Naira</option>
                    <option value="Norwegian krone">Norwegian krone</option>
                    <option value="Omani rial">Omani rial</option>
                    <option value="Pakistani rupee">Pakistani rupee</option>
                    <option value="Palestine Pound">Palestine Pound</option>
                    <option value="Balboa; U.S. dollar">
                      Balboa; U.S. dollar
                    </option>
                    <option value="Kina">Kina</option>
                    <option value="Guaraní">Guaraní</option>
                    <option value="Nuevo sol (1991)">Nuevo sol (1991)</option>
                    <option value="Peso">Peso</option>
                    <option value="Zloty">Zloty</option>
                    <option value="Qatari riyal">Qatari riyal</option>
                    <option value="Romanian Rupee">Romanian Rupee</option>
                    <option value="Ruble">Ruble</option>
                    <option value="Rwandan franc">Rwandan franc</option>
                    <option value="East Caribbean dollar">
                      East Caribbean dollar
                    </option>
                    <option value="Tala">Tala</option>
                    <option value="Dobra">Dobra</option>
                    <option value="Riyal">Riyal</option>
                    <option value="Serbian Dinar">Serbian Dinar</option>
                    <option value="Seychelles rupee">Seychelles rupee</option>
                    <option value="Leone">Leone</option>
                    <option value="Singapore dollar">Singapore dollar</option>
                    <option value="Solomon Islands dollar">
                      Solomon Islands dollar
                    </option>
                    <option value="Somali shilling">Somali shilling</option>
                    <option value="Rand">Rand</option>
                    <option value="Sri Lankan rupee">Sri Lankan rupee</option>
                    <option value="Sudanese Pound">Sudanese Pound</option>
                    <option value="Surinamese dollar">Surinamese dollar</option>
                    <option value="Lilangeni">Lilangeni</option>
                    <option value="Krona">Krona</option>
                    <option value="Swiss franc">Swiss franc</option>
                    <option value="Syrian pound">Syrian pound</option>
                    <option value="Taiwan dollar">Taiwan dollar</option>
                    <option value="somoni">somoni</option>
                    <option value="Tanzanian shilling">
                      Tanzanian shilling
                    </option>
                    <option value="Baht">Baht</option>
                    <option value="CFA Franc">CFA Franc</option>
                    <option value="Pa’anga">Pa’anga</option>
                    <option value="Trinidad and Tobago dollar">
                      Trinidad and Tobago dollar
                    </option>
                    <option value="Tunisian dinar">Tunisian dinar</option>
                    <option value="Turkish lira (YTL)">
                      Turkish lira (YTL)
                    </option>
                    <option value="Manat">Manat</option>
                    <option value="Tuvaluan Dollar">Tuvaluan Dollar</option>
                    <option value="Ugandan new shilling">
                      Ugandan new shilling
                    </option>
                    <option value="Hryvnia">Hryvnia</option>
                    <option value="U.A.E. Dirham">U.A.E. Dirham</option>
                    <option value="Pound sterling">Pound sterling</option>
                    <option value="Dollar">Dollar</option>
                    <option value="Uruguay peso">Uruguay peso</option>
                    <option value="Uzbekistani sum">Uzbekistani sum</option>
                    <option value="Vatu">Vatu</option>
                    <option value="Bolivar">Bolivar</option>
                    <option value="Dong">Dong</option>
                    <option value="Rial">Rial</option>
                    <option value="Kwacha">Kwacha</option>
                  </select>
                </div>

                <div className="reportlost-container-owner-reward-span-select-cnt">
                  <span>Reward</span>

                  <input
                    type="number"
                    // className="input-pet-details"
                    name="reward"
                    value={reward}
                    onChange={this.changeHandler}
                  />
                </div>
              </span>

              <div className="reportlost-container-owner-reward-btns">
                <Button variant="contained" onClick={this.handleBack}>
                  Back
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: "#ffc107" }}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={() => {
                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                  }}
                  variant="contained"
                >
                  CANCEL
                </Button>
              </div>
            </span>
          </div>
        );
    }
  };

  handleNext = () => {
    this.setState((prevActiveStep) => {
      console.log("prev", prevActiveStep);
      return {
        activeStep: prevActiveStep.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1,
      };
    });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  render() {
    const { sidebar, subMenu } = this.state;
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />
        <div className="keyreportlost-main-wrapper">
          <div className="keyreportlost-left-wrapper">
            <div className="keyreportlost-sidebar-toggle-btn-wrapper">
              <button
                className="keyreportlost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "keyreportlost-sidebar-container-hide"
                  : "keyreportlost-sidebar-container"
              }
            >
              <button
                className="keyreportlost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "keyreportlost-sidebar-menu-ul-hide"
                    : "keyreportlost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="keyreportlost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="keyreportlost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="keyreportlost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="keyreportlost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="keyreportlost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + this.props.match.params.tag_number;
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>
          <div className="keyreportlost-right-wrapper">
            <div className="col-xl-9 col-lg">
              <form onSubmit={this.submitHandler}>
                <Stepper
                  activeStep={this.state.activeStep}
                  alternativeLabel
                  className=""
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div
                  className="container uppercontainer bg-white py-3 px-0 mt-4 p-info text-center reportlostheight"
                  style={{
                    height: this.state.activeStep === 3 ? "100%" : "570px",
                  }}
                >
                  {this.getStepContent(this.state.activeStep)}
                </div>

                {this.state.showLoader && (
                  <div className="loader-container">
                    <div className="loader">
                      <img
                        src={Loader}
                        alt="Loading..."
                        loading="lazy"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                )}

                <Snackbar
                  open={this.state.snackbaropen}
                  autoHideDuration={3000}
                  onClose={this.snackbarClose}
                  message={this.state.snackbarmsg}
                  action={[
                    <IconButton
                      arial-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      X
                    </IconButton>,
                  ]}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
