import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./passportactivation.component.css";
// import Bagphoto from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import Modal from "@material-ui/core/Modal";
import { Country, State, City } from "country-state-city";
import $ from "jquery";
// import Button from "@material-ui/core/Button";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Navbar from "../header/header.component";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../assets/loader.gif";
import { APP_ENV, switchUrls } from "../../api/index.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import Sidebarcontent from "../sidebar/Sidebar.jsx";
import Footer from "../mainfooter/footerblack.jsx";
import { addDays } from "date-fns";

const baseURL = switchUrls("passport");
const gateway_frontend = switchUrls("gateway_frontend");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
    },
  },

  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

class LocationErrorModal extends React.Component {
  render() {
    const { open, handleClose, handleRetry } = this.props;

    return (
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px", // Adjust the width as needed
            textAlign: "center",
          }}
        >
          <p style={{ marginBottom: "15px" }}>
            Unable to retrieve your location. Please turn on your GPS.
          </p>
          <button
            onClick={handleRetry}
            style={{
              padding: "10px 15px",
              background: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Retry
          </button>
        </div>
      </Modal>
    );
  }
}

export default class BagDetails extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imageUrl: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/passport.png",
      passport_imagelocation: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "passport",
      valuable_desc: "",
      passport: "",
      dateofissue: "",
      Surname: "",
      givenName: "",
      nationality: "",
      placeofissue: "",
      address: "",
      validityperiod: "",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      snackbaropen: false,
      snackbarmsg: "",
      sidebar: true,
      subMenu: true,
      message: "",
      // vehicle_brand:"",
      // vehicle_otherkeys:"",
      // vehicle_licensenumber:"",
      // chasisnumber:"",
      // drivinglicense:"",
      showLoader: true,
      expirydate: "",
      imagemodal: false,
      crop: "",
      uploaderror: false,
      digitalqrdetails: {
        digital_product: false,
        no_of_days: null,
      },
      journey_startdate: "",
      journey_enddate: "",
      imagecroploading: false,
      errorstate: null,
      croperror: false,
      mindate: "",
      maxdate: "",
      timezone: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  uploadImg() {
    this.setState({
      uploaderror: false,
      message: false,
      imagemodal: false,
      imagecroploading: false,
      croperror: false,
    });
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  deleteimage = (e) => {
    if (this.state?.imageUrl) {
      this.setState({
        imageUrl: null,

        selectedImage: null,
      });
    }
  };
  async fetchPassportDetails() {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/passport/getPassportDetails/${this.state?.tag_number}`
      );
      console.log(data);
      if (data?.digital_product && data?.no_of_days) {
        this.setState({
          digitalqrdetails: {
            digital_product: data.digital_product,
            no_of_days: data?.no_of_days,
          },
        });
      }
    } catch (er) {
      console.log(er);
    }
  }
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sessionStorage.setItem("latitude", latitude);
          sessionStorage.setItem("longitude", longitude);
          this.setState({
            locationData: { latitude, longitude },
          });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          this.setState({ showLocationErrorModal: true });
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
    this.getLocation();
    this.fetchPassportDetails();
    this.setState({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  async handleChange(event) {
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);

      this.setState({ showLoader: true });
      const imageDimension = await this.imageSize(event.target.files[0]);
      //console.log(imageDimension);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(
        event.target.files[0],
        options
      );
      //console.log(compressedFile);
      const selectedImage = compressedFile;
      //console.log(selectedImage, "image");
      this.setState({ showLoader: false });
      if (selectedImage?.type) {
        if (
          selectedImage?.type === "image/jpeg" ||
          selectedImage?.type === "image/png" ||
          selectedImage?.type === "image/gif"
        ) {
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else if (
          selectedImage?.type === "image/heic" ||
          selectedImage?.type === "image/heif"
        ) {
          const convertedImageBlob = await heic2any({
            blob: compressedFile,
            toType: "image/jpeg",
            quality: 0.1, // cuts the quality and size
          });
          console.log(convertedImageBlob);
          let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
          console.log(sizeinmb);
          if (sizeinmb > 5) {
          }
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
      } else {
        this.setState({
          message: "Only Png and Jpeg Images Are Acceptable",
          uploaderror: true,
          imagemodal: true,
          imagecroploading: false,
        });
      }

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    } else {
      console.log("failed");
    }
  }
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    if (e?.target?.name === "journey_startdate") {
      const validDay = this.state?.digitalqrdetails?.no_of_days
        ? this.state?.digitalqrdetails?.no_of_days
        : 182;
      const startDate = new Date(e?.target?.value);
      //const maxDate = new Date(startDate);
      //maxDate.setDate(maxDate.getDate() + validDay);
      const calculated = addDays(new Date(startDate), parseInt(validDay, 10));
      //const minDateFormatted = startDate.toISOString().split('T')[0];
      const maxDateFormatted = calculated.toISOString().split("T")[0];
      console.log(maxDateFormatted);
      console.log(validDay);
      this.setState({
        maxdate: maxDateFormatted,
        journey_enddate: maxDateFormatted,
        [e.target.name]: e.target.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    let inactive;
    this.setState({ showLoader: true });

    const {
      tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      description,
      valuable_desc,
      passport,
      dateofissue,
      Surname,
      givenName,
      nationality,
      placeofissue,
      address,
      validityperiod,
      imageUrlvector,
      expirydate,
      digitalqrdetails,
      journey_startdate,
      journey_enddate,
      timezone,
    } = this.state;
    const { latitude, longitude } = this.state.locationData;

    const data = {
      tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      description,
      valuable_desc,
      passport,
      dateofissue,
      Surname,
      givenName,
      nationality,
      placeofissue,
      address,
      validityperiod,
      imageUrlvector,
      expirydate,
      latitude,
      longitude,
      digitalqrdetails,
      journey_startdate,
      journey_enddate,
      timezone,
    };
    let activationUrl = `${baseURL}/api/passport/Passportactivation`;
    if (digitalqrdetails?.digital_product) {
      activationUrl = `${baseURL}/api/passport/epassportactivation`;
    }
    axios
      .post(activationUrl, data, {
        headers: { Authorization: usertoken },
      })
      .then(({ data }) => {
        //console.log(data);
        if (data?.inactive) {
          inactive = true;
        }

        if (this?.state?.selectedImage !== null) {
          const fd = new FormData();

          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);
          axios
            .post(`${baseURL}/api/passport/passportimageupload`, fd)
            .then((res) => {
              this.setState({ showLoader: false });
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Passport Activated Successfully",
              });
              // Delay the redirection to ensure Snackbar is displayed
              setTimeout(() => {
                if (APP_ENV === "local") {
                  if (inactive) {
                    window.location.href =
                      gateway_frontend +
                      "/#/dashboard/" +
                      localStorage.getItem("uid") +
                      "?tab=inactive";
                  } else {
                    window.location.href = gateway_frontend + "/#/dashboard/";
                  }
                } else {
                  if (inactive) {
                    window.location.href =
                      gateway_frontend +
                      "/#/dashboard/" +
                      localStorage.getItem("uid") +
                      "?tab=inactive";
                  } else {
                    window.location.href = gateway_frontend + "/#/dashboard/";
                  }
                }
                // Redirect to the dashboard after a delay
              }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
            })
            .catch((error) => {
              this.setState({ showLoader: false });
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Image Upload Failed",
              });
              // Delay the redirection to ensure Snackbar is displayed
              setTimeout(() => {
                if (APP_ENV === "local") {
                  if (inactive) {
                    window.location.href =
                      gateway_frontend +
                      "/#/dashboard/" +
                      localStorage.getItem("uid") +
                      "?tab=inactive";
                  } else {
                    window.location.href = gateway_frontend + "/#/dashboard/";
                  }
                } else {
                  // Redirect to the dashboard after a delay
                  if (inactive) {
                    window.location.href =
                      gateway_frontend +
                      "/#/dashboard/" +
                      localStorage.getItem("uid") +
                      "?tab=inactive";
                  } else {
                    window.location.href = gateway_frontend + "/#/dashboard/";
                  }

                  /* window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");*/
                }
              }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
            });
        } else {
          this.setState({
            showLoader: false,
            snackbaropen: true,
            snackbarmsg: data?.message || "Success",
          });
          // Delay the redirection to ensure Snackbar is displayed
          setTimeout(() => {
            if (APP_ENV === "local") {
              if (inactive) {
                window.location.href =
                  gateway_frontend +
                  "/#/dashboard/" +
                  localStorage.getItem("uid") +
                  "?tab=inactive";
              } else {
                window.location.href = gateway_frontend + "/#/dashboard/";
              }
            } else {
              if (inactive) {
                window.location.href =
                  gateway_frontend +
                  "/#/dashboard/" +
                  localStorage.getItem("uid") +
                  "?tab=inactive";
              } else {
                window.location.href = gateway_frontend + "/#/dashboard/";
              }
            }
            //window.location.href = "http://localhost:3000/#/dashboard/" + localStorage.getItem("uid");
          }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
        }
      })
      .catch((error) => {
        console.log(error?.response?.data);

        this.setState({
          showLoader: false,
          snackbaropen: true,
          snackbarmsg: error?.response?.data?.message || "Failed",
        });
        if (!error?.response?.data?.dateError) {
          // Delay the redirection to ensure Snackbar is displayed
          setTimeout(() => {
            if (APP_ENV === "local") {
              window.location.href = gateway_frontend + "/#/dashboard";
            } else {
              // Redirect to the dashboard after a delay
              window.location.href =
                "/dashboard/" + localStorage.getItem("uid");
            }
          }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
        } else {
          //no redirection
        }
      });
  };

  cropsetup(e) {
    console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob((blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      this.setState({
        selectedImage: file,
        imageUrl: URL.createObjectURL(blob),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
      });
    });
  }

  render() {
    const {
      imageUrl,
      tag_number,
      dateofissue,
      warranty_period,
      passport,
      valuable_type,
      valuable_desc,
      Surname,
      givenName,
      nationality,
      locationData,
      showLocationErrorModal,
      placeofissue,
      address,
      validityperiod,
      sidebar,
      subMenu,
      expirydate,
      passport_imagelocation,
      imagemodal,
      imageUrlvector,
      uploaderror,
      crop,
      message,
      digitalqrdetails,
      journey_startdate,
      journey_enddate,
    } = this.state;
    const { latitude, longitude } = locationData;
    const today = () => {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var today = year + "-" + month + "-" + day;
      return today;
    };
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    // console.log("state : ", this.state);

    return (
      <>
        <Navbar />
        <div className="editPetDetails-main-wrapper">
          <div className="editPetDetails-left-wrapper">
            <Sidebarcontent />
          </div>
          <div className="form-group multi-preview">
            {(this.fileArray || []).map((url) => (
              <img
                src={url}
                className="ml-4 mr-2"
                alt="..."
                height="70px"
                width="70px"
              />
            ))}
          </div>
          <div className="editPetDetails-right-wrapper">
            <div id="breadcrumbs" className="mb-3"></div>
            <div className="petDetails ">
              {digitalqrdetails?.digital_product ? (
                <>
                  <div>
                    <p style={{ color: "red", textAlign: "center" }}>
                      Please register before your journey begins
                    </p>
                  </div>
                </>
              ) : null}
              <div className="editPetDetails-form-container ">
                <div className="editPetDetails-form-container-top">
                  <div className="editPetDetails-user-img">
                    {imageUrl?.length ? (
                      <>
                        <img
                          alt="imageurl"
                          src={imageUrl}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : passport_imagelocation ? (
                      <>
                        <img
                          alt="imagelocation"
                          src={passport_imagelocation}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          alt="imageyrlvector"
                          src={imageUrlvector}
                          className="editPetDetails-image"
                        />
                      </>
                    )}
                    <div className="editPetDetails-edit-btn-wrap">
                      <label
                        htmlFor="upload"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>

                      <input
                        ref={this.fileInputRef}
                        id="upload"
                        accept="image/*"
                        className="editPetDetails-input-user-img"
                        type="file"
                        onChange={this.handleChange}
                      />
                      {this.state.imageUrl ? (
                        <label
                          className="editPetDetails-delete-button-image "
                          onClick={this.deleteimage}
                        >
                          <AiIcons.AiFillDelete /> Remove
                        </label>
                      ) : null}
                    </div>
                    <div
                      className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                      style={{ gap: "6px" }}
                    >
                      <img
                        alt="qr"
                        src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ marginTop: "15px" }}>
                        <b>
                          {tag_number && tag_number !== "-"
                            ? tag_number
                            : "N/A"}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="editPetDetails-form-container-bottom mt-3"
                  style={{ width: "100%" }}
                >
                  <p style={{ textAlign: "center" }}>
                    <strong>
                      {digitalqrdetails?.digital_product
                        ? "tag8 Passport Security eTag Preactivation"
                        : "Passport Activation"}
                    </strong>
                  </p>
                  {digitalqrdetails?.digital_product && (
                    <p style={{ textAlign: "center" }}>
                      (valid for {digitalqrdetails?.no_of_days} Days from
                      journey start date)
                    </p>
                  )}
                  <form
                    className="editPetDetails-form"
                    onSubmit={this.submitHandler}
                  >
                    {/* given/first name and surename */}
                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          required
                          type="text"
                          // className="bagactivation-input-tag"
                          variant="outlined"
                          label="First Name"
                          name="givenName"
                          value={givenName}
                          onChange={this.changeHandler}
                          inputProps={{ maxLength: 30 }}
                        />
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          required
                          type="text"
                          // className="bagactivation-input-tag"
                          variant="outlined"
                          label="Surname"
                          name="Surname"
                          value={Surname}
                          onChange={this.changeHandler}
                          inputProps={{ maxLength: 30 }}
                        />
                      </div>
                    </div>

                    {/* expirydate and   passport no */}
                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          required
                          type="text"
                          // className="bagactivation-input-tag"
                          variant="outlined"
                          label="Passport Number"
                          name="passport"
                          value={passport}
                          onChange={this.changeHandler}
                          inputProps={{ maxLength: 25 }}
                        />
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          required
                          type="date"
                          // className="bagactivation-input-tag"
                          variant="outlined"
                          label="Passport Expiry Date"
                          name="expirydate"
                          value={expirydate}
                          onChange={this.changeHandler}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputProps: {
                              min: new Date().toISOString().split("T")[0], // Set minimum selectable date to today
                            },
                          }}
                        />
                      </div>
                    </div>
                    {digitalqrdetails?.digital_product &&
                    digitalqrdetails?.no_of_days ? (
                      <>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              required
                              type="date"
                              // className="bagactivation-input-tag"
                              variant="outlined"
                              label="Journey Start Date"
                              name="journey_startdate"
                              value={journey_startdate}
                              onChange={this.changeHandler}
                              InputProps={{
                                inputProps: {
                                  min: new Date().toISOString().split("T")[0], // Set minimum selectable date to today
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              disabled={journey_startdate ? false : true}
                              required
                              type="date"
                              // className="bagactivation-input-tag"
                              variant="outlined"
                              label="eTag Expiry Date"
                              name="journey_enddate"
                              value={journey_enddate}
                              //onChange={this.changeHandler}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/*  nationality */}
                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: "-7px", marginLeft: "13px" }}
                          >
                            Nationality
                          </InputLabel>
                          <Select
                            variant="outlined"
                            label="Nationality"
                            name="nationality"
                            required
                            value={
                              nationality?.charAt(0).toUpperCase() +
                              nationality?.slice(1)
                            }
                            onChange={this.changeHandler}
                          >
                            {Country?.getAllCountries()?.map((c) => {
                              return (
                                <MenuItem key={c.isoCode} value={c.name}>
                                  {c.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      {!digitalqrdetails?.digital_product && (
                        <div className="editPetDetails-input-wrapper">
                          <TextField
                            required
                            label="Date Of Issue"
                            variant="outlined"
                            type="date"
                            name="dateofissue"
                            value={dateofissue}
                            onChange={this.changeHandler}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: new Date().toISOString().split("T")[0], // Set minimum selectable date to today
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <LocationErrorModal
                      open={showLocationErrorModal}
                      handleClose={() =>
                        this.setState({ showLocationErrorModal: false })
                      }
                      handleRetry={this.handleRetry}
                      latitude={latitude}
                      longitude={longitude}
                    />

                    <div className="editPetDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                        {this.state.redirecttopetparent ? "NEXT" : "SUBMIT"}
                        {/* {this.state.redirecttopetparent ? "SUBMIT" : "NEXT"} */}
                      </Button>

                      <Button
                        type="button"
                        variant="contained"
                        style={{ width: "100%" }}
                        className="bg-white mt-2 cancel-btn submit-login "
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </form>

                  {this.state.showLoader && (
                    <div className="loader-container">
                      <div className="loader">
                        <img
                          src={Loader}
                          alt="Loading..."
                          loading="lazy"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  )}

                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    open={this.state.snackbaropen}
                    autoHideDuration={8000}
                    onClose={this.snackbarClose}
                    message={this.state.snackbarmsg}
                    action={[
                      <IconButton
                        arial-label="Close"
                        color="inherit"
                        onClick={this.snackbarClose}
                      >
                        X
                      </IconButton>,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
        </div>

        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button onClick={this.handelimagecrop}>Submit</Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
      </>
    );
  }
}
